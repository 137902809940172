.song-list {
    padding: 10px;
}

.song-card {
    display: flex;
    height: 120px;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    margin-bottom: 20px !important;
}

img {
    margin-left: 0 !important;
    margin-right: 0px !important;
    height:100px;
    width: auto;
}

.song-info-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding-bottom: 10px;
}

.song-about-container {
    flex: 50%;
    align-content: center;
}

.song-title {
    margin: 0 !important;
    padding-top: 5px;
    padding-bottom: 2px;
    width: 100%;
}

.song-artist {
    width: 100%;
}

.song-album {
    width: 100%;
}

.queue-song-button {
    border-width: 10px;
    text-align: center;
    padding: 0 15px !important;
    line-height: 0.5 !important;
}
.queue-song-button:hover {
    border-color: #2D2C54;
}