.rooms-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.room-cards {
    list-style: none;
    padding: 2%;
}

.room-card {
    width: 300px;
    text-align: center;
    padding: 20px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
}

.room-name {
    margin: 0 auto !important;
    padding-bottom: 20px;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}