.signup-body {
  padding: 20px !important;
  width: 100%;
  height: 120%;
}

.input-text {
  color: black !important;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.06);
}

.center {
  display: block;
  text-align: center;
}